<template>
  <div>
    <gmap-autocomplete
      class="introInput"
      @place_changed="getAddressData"
      :options="autocompleteOptions"
      select-first-on-enter
    >
      <template #input="slotProps">
        <v-text-field
          outlined
          prepend-inner-icon="place"
          placeholder="Wyszukaj adres"
          label="Adres bazy oddziału"
          :value="selectedAddress"
          ref="input"
          hide-details
          validate-on-blur
          @listeners="slotProps.listeners"
          @attrs="slotProps.attrs"
        >
          <template #prepend-inner>
            <Icon
              name="search"
              size="small"
              class="mr-2"
            />
          </template>
        </v-text-field>
      </template>
    </gmap-autocomplete>

    <v-input
      :value="selectedAddress"
      :rules="[rules.required]"
      hide-details
      class="validated-label"
    >
      <div class="mr-1">
        Wybrany adres:
      </div>
      <span class="font-weight-medium">{{ selectedAddress || '-' }}</span>
    </v-input>
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'
import { getGmapsAddressParts } from '../../../utils'

export default {
  mixins: [inputMixin],
  data () {
    return {
      selectedAddress: '',
      autocompleteOptions: {
        fields: ['adr_address', 'formatted_address', 'geometry.location'],
        componentRestrictions: {
          country: ['pl']
        }
      }
    }
  },
  mounted () {
    this.selectedAddress = get(this.data, this.field.value, '')
  },
  methods: {
    getAddressData (e) {
      const { formattedAddress, lat, lng } = getGmapsAddressParts(e)

      this.selectedAddress = formattedAddress
      this.$emit('update', {
        [this.field.name]: {
          address: formattedAddress,
          lat,
          lng
        }
      })
    }
  }
}
</script>
